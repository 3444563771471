import React, { useState, useEffect } from "react";
import {ThemeProvider, Grid} from "@mui/material";
import Header from "../Common/Headers/header";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Breadcrumb from "../Common/Breadcrumbs/breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import { LeftPanelComponent, CollectionsMenu, ComponentMapping, theme } from "../../constant2";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Collections = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [selectedMenu, setSelectedMenu] = useState("BI Report");
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    const accessLevel = levelsofAccess("Collection");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage( "You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  const handleMenuSelect = (menu) => {
    setSelectedMenu(menu);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
      <Header />
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{color:"#ffffff"}}/>} className="breadcrumbsStyle" sx={{mt:1}}>
      <Breadcrumb
          icon={<AssignmentIndIcon />}
          name={<span>Dashboard</span>}
          path="../../Dashboard"
        />
        <Breadcrumb
          icon={<PaymentIcon />}
          name={<span>Collections and Reports</span>}
          path="/Collections"
          isCurrentPage
        />
      </Breadcrumbs>
      {showData ? (<>
        <Grid container spacing={2} style={{ height: "78vh", width: "100%" }} sx={{paddingTop:"5px"}}>
        <LeftPanelComponent menuItems={CollectionsMenu} componentMapping={ComponentMapping} />
      </Grid>
      </>):(<>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Alert severity="error" sx={{m:5}}>{alertMessage}</Alert>
            </Grid>
          </Grid>
      </>)
      }
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};
export default Collections;
