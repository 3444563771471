import React, { useState, useEffect } from "react";
import {Grid, ThemeProvider, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { DropzoneArea } from "material-ui-dropzone";
import { blue } from "@mui/material/colors";
import { uploadCollectionReports, getLoanCategories } from "../Common/Apis/api";
import Alert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Import locale if needed
import { useNavigate } from "react-router-dom";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { FormControl, InputLabel, Select } from "@mui/material";
import logout from "../Common/Logout/logout";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import { theme } from "../../constant2";

dayjs.locale("en");
const UploadDataComponentCollections = () => {
  const dispatch = useDispatch();
  const [alertText, setAlertText] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isfileUploaded, setisfileUploaded] = useState(false);
  const [isAlert, setisAlert] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const [isUploadReady, setisUploadReady] = useState(false);
  const [isfileUploadedSuccessful, setisfileUploadedSuccessful] =
    useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  // const yesterday = dayjs().subtract(1, "day").toDate();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const handleDateChangeFrom = (date) => {
    setisAlert(false);
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedDateFrom(formattedDate);
  };
  const loanCategories = useSelector(
    (state) => state.DetailsReducers.loanCategoryResponse
  );
  const loanCategoriesLoading = useSelector(
    (state) => state.DetailsReducers.loanReportLoading
  );
  const handleDateChangeTo = (date) => {
    setisAlert(false);
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedDateTo(formattedDate);
  };
  useEffect(() => {
    dispatch(getLoanCategories(handleLogoutButton));
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  useEffect(() => {
    if (
      selectedCategory != "" &&
      selectedDateFrom != null &&
      selectedDateTo != null
    ) {
      if (selectedDateFrom > selectedDateTo) {
        setAlertText('Please select a date after the "From" date.');
        setisAlert(true);
        setisUploadReady(false);
      } else {
        setisUploadReady(true);
      }
    }
  }, [selectedCategory, selectedDateFrom, selectedDateTo]);
  const handleFileUpload = (files) => {
    // Assuming you want to handle only one file, you can access it using files[0]
    setUploadedFile(files[0]);
    if (isUploadReady == true) {
      setisDisabled(false);
    }
  };

  useEffect(() => {
    const accessLevel = levelsofAccess("Collection");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage("You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  const handleUploadData = async () => {
    setisfileUploaded(false);
    setisfileUploadedSuccessful(false);
    if (uploadedFile == null) {
      setisfileUploaded(true);
      return;
    }
    // Add your logic for handling the file upload here
    let data = null;
    data = new FormData();
    data.append("from_date", selectedDateFrom);
    data.append("to_date", selectedDateTo);
    data.append("collection_report", uploadedFile);
    try {
      const results = await uploadCollectionReports(data, handleLogoutButton);
      if (results) {
        setisfileUploadedSuccessful(true);
      }
    } catch (error) { }
    // Add logic for uploading data to the server
  };
  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  return (
    <>
      <div>
      <ThemeProvider theme={theme}>
        {loanCategoriesLoading ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : null}
        {/* Breadcrumbs */}
        <div style={{ height: "78vh", overflow: "auto" }}>
          {showData ? (<>
          <Grid
            container
            spacing={2}
            sx={{ mt: 2 }}             //to remove x axis scroll
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <Typography variant="h6" gutterBottom>
              <span>Upload Collections Data</span>
            </Typography>
          </Grid>
          <div
            style={{
              width: "90%",
              margin: "auto",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            {isfileUploaded ? (
              <Alert sx={{ m: 2 }} severity="error">
                Please choose a file to upload.
              </Alert>
            ) : null}
            {isAlert ? (
              <Alert sx={{ m: 2 }} severity="error">
                {alertText}
              </Alert>
            ) : null}
            {isfileUploadedSuccessful ? (
              <Alert sx={{ m: 2 }} severity="success">
                File Uploaded Successfully!
              </Alert>
            ) : null}
            {loanCategories?.main?.length == 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Alert severity="info">
                    Upload Collections Data functionality is available only for
                    Loan Originators.
                  </Alert>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={4}>
                    {loanCategories ? (
                      <FormControl fullWidth>
                        <InputLabel id="loan-category-label">
                          Loan Category
                        </InputLabel>
                        <Select
                          labelId="loan-category-label"
                          id="loan-category-select"
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                          label="Loan Category"
                        >
                          {loanCategories?.main?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              <span>{item.loan_category}</span>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : null}
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        label="From"
                        views={["year", "month", "day"]}
                        // value={selectedDate}
                        onChange={handleDateChangeFrom}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        label="To"
                        views={["year", "month", "day"]}
                        // value={selectedDate}
                        //minDate={selectedDateFrom}
                        onChange={handleDateChangeTo}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <div
                  style={
                    !isUploadReady
                      ? { pointerEvents: "none", opacity: 0.5, margin: "20px" }
                      : { margin: "20px" }
                  }
                >
                  <DropzoneArea
                    acceptedFiles={[".xls"]}
                    filesLimit={1}
                    maxFileSize={5000000} // 5 MB limit
                    dropzoneText={<span>Drag and drop a .xls file here or click</span>}
                    onChange={handleFileUpload}
                    showAlerts={false}
                    showFileNames={true}
                    dropzoneClass={`
      border: 2px dashed ${blue[500]};
      background-color: #f8f8f8;
      border-radius: 8px;
    `}
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUploadData}
                  disabled={!uploadedFile}
                  style={
                    uploadedFile
                      ? {
                        color: "#fff",
                        backgroundColor: "#1976d2",
                        //boxShadow:"0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                        // Add other styles as needed
                        fontWeight: "500",
                        fontSize: "0.875rem",
                        lineHeight: "1.75",
                        letterSpacing: "0.02857em",
                        textTransform: "uppercase",
                        minWidth: "64px",
                        padding: "6px 16px",
                        borderRadius: "4px",
                      }
                      : null
                  }
                >
                   Upload Data
                </Button>
              </>
            )}
          </div>
          </>):(<><Grid item xs={12} md={12}>
            <Alert severity="error" sx={{m:5}}>{alertMessage}</Alert>
          </Grid></>)}
        </div>
        <ReusableSnackbar
          open={showSnackbar}
          onClose={closeSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
        </ThemeProvider>
      </div>
    </>
  );
};

export default UploadDataComponentCollections;
