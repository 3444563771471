import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import {Typography,ThemeProvider, Button} from "@mui/material";
import { styled, textTransform } from "@mui/system";
import useTheme from "@mui/system/useTheme";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../constant2";

const StyledLink = styled(Link)(({ theme }) => ({
  color: "#000000",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  cursor: "pointer", // Add cursor: pointer for styling
}));

const Breadcrumb = ({ icon, name, path, isCurrentPage }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigation = (e) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <>
    <ThemeProvider theme={theme}>
     <div>
        {isCurrentPage ? (
          <Button sx={{textTransform:"none"}}>
          <Typography color="textSecondary" sx={{ margin: theme.spacing(1) }} style={{fontSize:"1rem", color:"#ffffff"}}>
            {icon &&
              React.cloneElement(icon, {
                sx: {
                  // marginLeft: 1,
                  // marginRight: 1,
                  width: 20,
                  height: 20,
                  verticalAlign: "middle",
                },
              })}
              {name}
          </Typography>
          </Button>
        ) : (
          <Button sx={{textTransform:"none"}}>
          <StyledLink
            onClick={handleNavigation}
            underline="hover"
            theme={theme}
            style={{color:"#ffffff"}}
            // sx={{
            //   textDecoration: 'none',
            //   backgroundColor: '#c8d9ff',
            //   padding: '4px 8px',
            //   borderRadius: '8px', 
            //   color: '#000',
            //   fontWeight: 'bold',
            //   '&:hover': {
            //     backgroundColor: '#e4ecff', 
            //   },
            // }}
          >
            {icon &&
              React.cloneElement(icon, {
                // sx: {
                //   // marginLeft: 1,
                //   // marginRight:1,
                //   width: 20,
                //   height: 20,
                //   verticalAlign: "middle",
                // },
              })}
            <Typography color="inherit" style={{fontSize:"1rem"}}>{name}</Typography>
          </StyledLink>
          </Button>
        )}
      </div>
      </ThemeProvider>
    </>
  );
};

export default Breadcrumb;
