import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Alert, ThemeProvider, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { downloadReports, getReports } from "../Common/Apis/api";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en"; // Import locale if needed
import { useNavigate } from "react-router-dom";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { FormControl, InputLabel, Select } from "@mui/material";
import logout from "../Common/Logout/logout";
import { blueButtonStyle, greyButtonStyle, theme } from "../../constant2";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
dayjs.locale("en");
const FinancialReports = () => {
  const dispatch = useDispatch();
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTill, setSelectedDateTill] = useState(null);
  const [downloadReportLoading, setdownloadReportLoading] =
    React.useState(false);
  const [reportType, setReportTypeCategory] = useState("");
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [showFromData, setShowFromDate] = useState(false);
  const [isAlert, setisAlert] = useState(false);
  const [alertText, setAlertText] = useState("");

  const handleDateChangeFrom = (date1) => {
    setisAlert(false);
    const formattedDate1 = dayjs(date1).format("YYYY-MM-DD");
    setSelectedDateFrom(formattedDate1);
    // console.log(formattedDate1);
  };

  const handleDateChangeTill = (date2) => {
    setisAlert(false);
    const formattedDate2 = dayjs(date2).format("YYYY-MM-DD");
    setSelectedDateTill(formattedDate2);
    // console.log(formattedDate2);
  };


  const reportTypeResponse = useSelector(
    (state) => state.DetailsReducers.reportTypeResponse
  );
  const reportTypeLoading = useSelector(
    (state) => state.DetailsReducers.reportTypeLoading
  );
  useEffect(() => {
    dispatch(getReports(handleLogoutButton));
  }, []);

  useEffect(() => {
    if (
      reportType != "" &&
      selectedDateFrom != null &&
      selectedDateTill != null
    ) {
      if (selectedDateFrom > selectedDateTill) {
        setAlertText('Please select a date after the "From" date.');
        setisAlert(true);
      } else {
        setisAlert(false);
      }
    }
  }, [reportType, selectedDateFrom, selectedDateTill]);

  const handleCategoryChange = (event) => {
    let value = event.target.value;
    setisAlert(false);
    setReportTypeCategory(event.target.value);
    // console.log(value);
    if (value === "Collection") {
      setShowFromDate(true);
      setSelectedDateFrom(null);
      setSelectedDateTill(null);
    }
    else {
      setShowFromDate(false);
      setSelectedDateFrom(null);
      setSelectedDateTill(null);
    }
  };

  const handledownloadReports = async () => {
    // Add your logic for handling the file upload here
    setdownloadReportLoading(true);
    try {
      const results = await downloadReports(
        reportType,
        selectedDateFrom,
        selectedDateTill,
        handleLogoutButton
      );
      if (results) {
        setdownloadReportLoading(false);
        downloadExcelFromBase64(results.data, reportType + " Reports");
      }
    } catch (error) {
      setdownloadReportLoading(false);
    }
    // Add logic for uploading data to the server
  };

  useEffect(() => {
    const accessLevel = levelsofAccess("Collection");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage("You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  const downloadExcelFromBase64 = (base64String, fileName) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  return (
    <>
      <div>
        <ThemeProvider theme={theme}>
          {reportTypeLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
              {/* <CustomLoader/> */}
            </Box>
          ) : null}
          {downloadReportLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
              {/* <CustomLoader/> */}
            </Box>
          ) : null}
          {/* Breadcrumbs */}
          <div style={{ height: "78vh", overflow: "auto" }}>
            {showData ? (<>
              <Grid
                container
                spacing={2}
                sx={{ mt: 2 }}   //to remove x axis scroll
                style={{ textAlign: "center", justifyContent: "center" }}
              >
                <Typography variant="h6" gutterBottom>
                  Financial Reports
                </Typography>
              </Grid>
              {isAlert ? (
                  <Alert sx={{ m: 2 }} severity="error">
                   {alertText}
                  </Alert>
              ) : null}
              <div
                style={{
                  width: "90%",
                  margin: "auto",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={4} md={4}>
                    {reportTypeResponse ? (
                      <FormControl fullWidth>
                        <InputLabel id="loan-category-label">Report Type</InputLabel>
                        <Select
                          labelId="loan-category-label"
                          id="loan-category-select"
                          value={reportType}
                          onChange={handleCategoryChange}
                          label="Report Type"
                        >
                          {reportTypeResponse.map((item) => (
                            <MenuItem key={item} value={item}>
                              <span>{item}</span>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : null}
                  </Grid>
                  {showFromData ? (<>
                    <Grid item xs={2} md={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          label="From Date"
                          views={["year", "month", "day"]}
                          value={selectedDateFrom}
                          onChange={handleDateChangeFrom}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          label="Till Date"
                          views={["year", "month", "day"]}
                          value={selectedDateTill}
                          onChange={handleDateChangeTill}
                        // shouldDisableDate={disablePastDates}
                        />
                      </LocalizationProvider>
                    </Grid></>) : (<>
                      <Grid item xs={4} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            fullWidth
                            label="Till Date"
                            views={["year", "month", "day"]}
                            value={selectedDateTill}
                            onChange={handleDateChangeTill}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </>)}
                  <Grid item xs={4} md={4}>
                    <Button
                      sx={reportType !== "" && selectedDateTill !== null && !isAlert ? { ...blueButtonStyle } : { ...greyButtonStyle }}
                      // variant="contained"
                      // color="primary"
                      onClick={handledownloadReports}
                      style={{
                        height: "7.5vh",
                        width: "20vw",
                      }}
                      disabled={reportType === "" || selectedDateTill === null || isAlert}
                    >
                      Download Reports
                    </Button>
                  </Grid>
                  {/* <Grid item xs={4} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        label="Till Date"
                        views={["year", "month", "day"]}
                        // value={selectedDate}
                        onChange={handleDateChangeFrom}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Button
                      sx={reportType == "" || selectedDateFrom == null ? { ...greyButtonStyle } : { ...blueButtonStyle }}
                      // variant="contained"
                      // color="primary"
                      onClick={handledownloadReports}
                      style={{
                        height: "7.5vh",
                        width: "20vw",
                      }}
                      disabled={reportType == "" || selectedDateFrom == null}
                    >
                      Download Reports
                    </Button>
                  </Grid> */}
                </Grid>
              </div>
            </>
            ) : (
              <><Grid item xs={12} md={12}>
                <Alert severity="error" sx={{ m: 5 }}>{alertMessage}</Alert>
              </Grid></>)}
          </div>
          <ReusableSnackbar
            open={showSnackbar}
            onClose={closeSnackbar}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default FinancialReports;
