import React, { useState, useEffect } from "react";
import {Grid,ThemeProvider, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Header from "../Common/Headers/header";
import { Alert } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import Breadcrumb from "../Common/Breadcrumbs/breadcrumbs";
import {
  getLoanReports,
  getLoanReportsDetails,
  getLoanCategories,
} from "../Common/Apis/api";
import { Paper } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import ReusableSnackbar from "./../Common/Snackbar/snackbar";
import Box from "@mui/material/Box";
import Label from "@mui/icons-material/Label";
import ForumIcon from "@mui/icons-material/Forum";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LinearProgress from "@mui/material/LinearProgress";
import { TreeView } from "@mui/x-tree-view/TreeView";
import logout from "../Common/Logout/logout";
import { StyledTreeItem, blueButtonStyle, theme, indianNumberFormat } from "../../constant2";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import CountUp from 'react-countup';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loanReports = useSelector(
    (state) => state.DetailsReducers.loanReportResponse
  );
  const LoanCategoriesLoading = useSelector(
    (state) => state.DetailsReducers.LoanCategoriesLoading
  );
  const loanReportsLoading = useSelector(
    (state) => state.DetailsReducers.loanReportLoading
  );
  const loanCategoryResponse = useSelector(
    (state) => state.DetailsReducers.loanCategoryResponse
  );
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [data, setdata] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  
  const handleLoanClick = (loan, key) => {
    setSelectedNodeId(loan.id);
    dispatch(getLoanReports(loan.id, handleLogoutButton, key));
    sessionStorage.setItem("key", key);
    sessionStorage.setItem("LoanCategory", loan.id);
    //setSelectedLoan(loan);
  };
  const navigatetoMoreDetails = (e) => {
    navigate("../../DetailView");
  };
  useEffect(() => {
    //dispatch(getLoanReports(handleLogoutButton));
    dispatch(getLoanCategories(handleLogoutButton));
  }, []);
  useEffect(() => {
    if (loanReports != undefined && loanReports != null) {
      setdata(loanReports);
      setSelectedLoan(loanReports);
      // setSelectedNodeId(loanReports[0]?.loan_category_id);
    }
  }, [loanReports]);

  
  useEffect(() => {
    const accessLevel = levelsofAccess("Disbursement");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage( "You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <div>
    <ThemeProvider theme={theme}>
      <Header />
      {loanReportsLoading && LoanCategoriesLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          <CustomLoader />
        </Box>
      ) : null}
      {/* Breadcrumbs */}
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{color:"#ffffff"}}/>} className="breadcrumbsStyle" sx={{mt:1}}>
        <Breadcrumb
          icon={<AssignmentIndIcon />}
          name="Dashboard"
          path="/Dashboard"
        />
        <Breadcrumb
          icon={<CardTravelIcon />}
          name="Approvals and Disbursements"
          path="/Approvals"
          isCurrentPage
        />
      </Breadcrumbs>
      <Grid container spacing={2}>
        {/* Left side - Loan Categories Menu */}
        {showData ?(<>
        {/* {editAccess ?(<>
          <Grid item xs={12} md={12}>
              <Alert severity="error" sx={{m:5}}>You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.</Alert>
            </Grid>
            </>):(<> */}
        <Grid item xs={1}></Grid>
        <Grid item xs={12} sx={{ mt: 4 }} md={3} lg={3}>
          <Paper elevation={3} style={{ padding: "10px", height: "70vh" }}>
            {loanCategoryResponse ? (
              <TreeView
                aria-label="gmail"
                defaultExpanded={["3"]}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
                sx={{
                  height: "66vh",
                  flexGrow: 1,
                  maxWidth: 400,
                  overflowY: "auto",
                }}
              >
                {Object.keys(loanCategoryResponse).map((key) => (
                  <StyledTreeItem
                    key={key}
                    nodeId={key}
                    labelText={key}
                    labelIcon={Label}>
                    {loanCategoryResponse[key].map((category) => (
                      <StyledTreeItem
                        key={category.id}
                        nodeId={`${key}-${category.id}`}
                        labelText={category.loan_category}
                        labelIcon={ForumIcon}
                        color="var(--styled-tree-text-color)"
                        bgColor="var(--styled-tree-bg-color)"
                        colorForDarkMode="var(--darkmode-styled-tree-text-color)"
                        bgColorForDarkMode="var(--darkmode-styled-tree-bg-color)"
                        onClick={() => handleLoanClick(category, key)}
                      />
                    ))}
                  </StyledTreeItem>
                ))}
              </TreeView>
            ) : null}
            {/* <Typography variant="h6">Loan Categories</Typography> */}
            {/* <div style={{ fontSize: "1.1rem" }}>
              {data.map((loan) => (
                <div
                  key={loan.loan_category_id}
                  onClick={() => handleLoanClick(loan)}
                  style={{
                    color:
                      selectedLoan?.loan_category_id === loan?.loan_category_id
                        ? "var(--primary-color)"
                        : "inherit",
                    cursor: "pointer",
                    margin: "4%",
                  }}
                >
                  {loan.loan_category}
                </div>
              ))}
            </div> */}
          </Paper>
        </Grid>

        {/* Right side - Loan Details */}
        <Grid item xs={12} md={7} lg={7} sx={{ mt: 4 }}>
          <Paper
            elevation={0}
            style={{
              padding: "10px",
              height: "70vh",
              boxShadow: "none !important",
            }}
          >
            {selectedLoan ? (
              <>
                <Grid xs={12}>
                  <Typography variant="h6">
                    Loan Category- {selectedLoan?.loan_category}
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2, pl: 4, pb: 4 }}>
                  <Grid item xs={12} lg={4} md={4}>
                    <Card
                      variant="outlined"
                      style={{
                        height: "18vh",
                        background: "var(--primary-color)",
                        color: "var(--white-color)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 16 }}
                          gutterBottom
                        >
                          Total Loan Amount:
                        </Typography>
                        <Typography variant="h5" component="div">
                          ₹
                        <CountUp
                          start={0}
                          end={selectedLoan?.total_loan_amount}
                          duration={2}
                          formattingFn={indianNumberFormat}
                        />
                          {/* {selectedLoan?.total_loan_amount?.toLocaleString(
                            "en-IN",
                            {
                              style: "currency",
                              currency: "INR",
                            }
                          )} */}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <Card
                      variant="outlined"
                      style={{
                        height: "18vh",
                        background: "var(--primary-color)",
                        color: "var(--white-color)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 16 }}
                          gutterBottom
                        >
                          Customers Applied:
                        </Typography>
                        <Typography variant="h5" component="div">
                        <CountUp
                          start={0}
                          end={selectedLoan?.customers_applied}
                          duration={2}
                          separator=","
                        />
                          {/* {selectedLoan?.customers_applied} */}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <Card
                      variant="outlined"
                      style={{
                        height: "18vh",
                        background: "var(--primary-color)",
                        color: "var(--white-color)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 16 }}
                          gutterBottom
                        >
                        Approval Rate:
                        </Typography>
                        <Typography variant="h5" component="div">
                        <CountUp
                          start={0}
                          end={selectedLoan?.approval_rate}
                          duration={2}
                          separator=","
                          decimals={2}
                          decimal="."
                          suffix="%"
                        />
                          {/* {selectedLoan?.approval_rate} % */}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <Card
                      variant="outlined"
                      style={{
                        height: "18vh",
                        background: "var(--primary-color)",
                        color: "var(--white-color)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 16 }}
                          gutterBottom
                        >
                        Total Disbursed Amount:
                        </Typography>
                        <Typography variant="h5" component="div">
                          {/* {selectedLoan?.total_loan_amount?.toLocaleString(
                            "en-IN",
                            {
                              style: "currency",
                              currency: "INR",
                            }
                          )} */}
                          ₹<CountUp
                           start={0}
                           end={selectedLoan?.net_disbursement}
                           duration={2}
                           separator=","
                           decimals={2}
                           decimal="."
                           formattingFn={indianNumberFormat}
                          />
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <Card
                      variant="outlined"
                      style={{
                        height: "18vh",
                        background: "var(--primary-color)",
                        color: "var(--white-color)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 16 }}
                          gutterBottom
                        >
                        Total Loans Disbursed:
                        </Typography>
                        <Typography variant="h5" component="div">
                          {/* {selectedLoan?.customers_applied} */}
                          <CountUp
                           start={0}
                           end={selectedLoan?.loan_disbursed}
                           duration={2}
                           separator=","
                        />
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={2} sx={{ mt: 2, pl: 4, pb: 4 }}>
                  <Grid item xs={4}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Total Loan Amount (Placeholder):
                        </Typography>
                        <Typography variant="h5" component="div">
                          {selectedLoan?.total_loan_amount}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Customers Applied (Placeholder):
                        </Typography>
                        <Typography variant="h5" component="div">
                          {selectedLoan?.customers_applied}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Approval Rate <br />
                          (Placeholder):
                        </Typography>
                        <Typography variant="h5" component="div">
                          {selectedLoan?.approval_rate}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid> */}
                <Grid container justifyContent="right">
                  <Button
                    sx={{mt:7,...blueButtonStyle}}
                    type="submit"
                    onClick={(event) => navigatetoMoreDetails(event)}
                  >
                    More Details
                  </Button>
                </Grid>
              </>
            ) : (
              <Typography>Select a loan category from the menu</Typography>
            )}
          </Paper>
        </Grid>
        </>):(<>
            <Grid item xs={12} md={12}>
              <Alert severity="error" sx={{m:5}}>{alertMessage}</Alert>
            </Grid>
        </>)}
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};

export default Index;
