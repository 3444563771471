import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Header from "../Common/Headers/header";
import {Breadcrumbs, ThemeProvider, Typography} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import Breadcrumb from "../Common/Breadcrumbs/breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { getLoanDisbursement } from "../Common/Apis/api";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import Alert from "@mui/material/Alert";
import { Paper } from "@material-ui/core";
import "./approvals.css";
import logout from "../Common/Logout/logout";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import { theme } from "../../constant2";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const DisbursementDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loanDisbursement = useSelector(
    (state) => state.DetailsReducers.loanDisbursementResponse
  );
  const loanDisbursementLoading = useSelector(
    (state) => state.DetailsReducers.loanDisbursementLoading
  );
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const formatKey = (key) => {
    return key.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase());
  };
  useEffect(() => {
    try {
      const loanScheduleID = sessionStorage.getItem("LoanScheduleId");
      dispatch(getLoanDisbursement(loanScheduleID, handleLogoutButton));
    } catch (e) {
    }
  }, []);

  useEffect(() => {
  }, [loanDisbursement]);

  useEffect(() => {
    const accessLevel = levelsofAccess("Disbursement");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage( "You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  return (
    <div>
    <ThemeProvider theme={theme}>
      <Header />
      {loanDisbursementLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          <CustomLoader/>
        </Box>
      ) : null}
      {/* Breadcrumbs */}
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{color:"#ffffff"}}/>} className="breadcrumbsStyle" sx={{mt:1}}>
        <Breadcrumb
          icon={<AssignmentIndIcon />}
          name="Dashboard"
          path="/Dashboard"
        />
        <Breadcrumb
          icon={<CardTravelIcon />}
          name="Approvals and Disbursements"
          path="/Approvals"
        />
        <Breadcrumb icon={<FileCopyIcon />} name="Details" path="/DetailView" />
        <Breadcrumb
          icon={<ScheduleIcon />}
          name="Disbursement Details"
          path="/DisbursementDetails"
          isCurrentPage
        />
      </Breadcrumbs>
      {showData ? (<>
      {loanDisbursement ? (
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          style={{ width: "100%" }}
        >
          <Grid
            container
            spacing={2}
            sx={{ ml: 4, mr: 4, mt: 4, width: "90%" }}
          >
            <div className="details-box">
              <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                {Object.keys(loanDisbursement).map((key) => (
                  <>
                    {key == "id" && key == "display_id" ? (
                      <Grid item xs={12} sm={3} md={3}>
                        <Typography
                          sx={{ mr: 4, flex: 1 }}
                          component="div"
                          style={{ fontWeight: "700", fontSize: "0.8rem" }}
                        >
                          {formatKey(key)}:
                        </Typography>
                        <Typography
                          sx={{ mr: 4, flex: 1 }}
                          component="div"
                          style={{ fontSize: "0.9rem" }}
                        >
                          {loanDisbursement[key]}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={3} md={3}>
                        <Typography
                          sx={{ mr: 4, flex: 1 }}
                          component="div"
                          style={{ fontWeight: "700", fontSize: "0.8rem" }}
                        >
                          {formatKey(key)}:
                        </Typography>
                        <Typography
                          sx={{ mr: 4, flex: 1 }}
                          component="div"
                          style={{ fontSize: "0.9rem" }}
                        >
                          {key === "id"
                            ? loanDisbursement[key]
                            : loanDisbursement[key]?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })}
                        </Typography>
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Alert severity="warning">
          The disbursement is not yet generated for this loan.{" "}
        </Alert>
      )}
      </>):(<>
        <Grid item xs={12} md={12}>
              <Alert severity="error" sx={{m:5}}>{alertMessage}</Alert>
            </Grid>
      </>)}
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};

export default DisbursementDetails;
